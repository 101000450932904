var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      model: _vm.option,
      title: "Opções de atualização",
      show: _vm.dialog,
      maxWidth: "30%",
      height: "70",
      icon: "mdi-refresh-circle",
      showButton: false
    },
    on: {
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-radio-group",
              {
                model: {
                  value: _vm.option,
                  callback: function($$v) {
                    _vm.option = $$v
                  },
                  expression: "option"
                }
              },
              [
                _c("v-radio", {
                  attrs: { label: "Atualizar somente esse item", value: "1" }
                }),
                _c("v-radio", {
                  attrs: {
                    label: "Atualizar esse item e os próximos",
                    value: "2"
                  }
                }),
                _c("v-radio", {
                  attrs: { label: "Atualizar todos os itens", value: "3" }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "actions",
        fn: function() {
          return [
            _c(
              "v-btn",
              {
                attrs: { color: "primary" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.changed()
                  }
                }
              },
              [
                _vm._v("Atualizar"),
                _c("v-icon", { attrs: { right: "", dark: "" } }, [
                  _vm._v("mdi-content-save")
                ])
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.item,
      title: _vm.title,
      icon: _vm.item.is_receipt
        ? "mdi-account-cash-outline"
        : "mdi-cash-register",
      show: _vm.dialog,
      maxWidth: "50%",
      color: _vm.color,
      height: "70"
    },
    on: {
      onSave: _vm.optionSave,
      "update:model": function($event) {
        _vm.item = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-row",
              [
                _vm.message
                  ? _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "v-alert",
                          {
                            attrs: {
                              outlined: "",
                              color: "warning",
                              prominent: "",
                              border: "left"
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.message) + " ")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "v-row",
              { attrs: { justify: "center", align: "center" } },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "auto" } },
                  [
                    _c(
                      "v-btn-toggle",
                      {
                        attrs: { color: _vm.color },
                        model: {
                          value: _vm.item.is_receipt,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "is_receipt", $$v)
                          },
                          expression: "item.is_receipt"
                        }
                      },
                      [
                        _c("v-btn", { attrs: { value: 1 } }, [
                          _vm._v(" Entrada ")
                        ]),
                        _c(
                          "v-btn",
                          { staticClass: "px-6", attrs: { value: 0 } },
                          [_vm._v(" Saída ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c("EmcFormTextField", {
                  attrs: {
                    label: "Título*",
                    md: "6",
                    outlined: "",
                    error: _vm.errors.length > 0,
                    "error-messages": _vm.errors.name
                  },
                  model: {
                    value: _vm.item.name,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "name", $$v)
                    },
                    expression: "item.name"
                  }
                }),
                _c("SelectFinancialPostingType", {
                  attrs: {
                    md: "6",
                    outlined: "",
                    label: "Categoria*",
                    isReceipt: _vm.item.is_receipt,
                    error: _vm.errors.length > 0,
                    "error-messages": _vm.errors.financial_posting_type_id
                  },
                  on: {
                    "update:isReceipt": function($event) {
                      return _vm.$set(_vm.item, "is_receipt", $event)
                    },
                    "update:is-receipt": function($event) {
                      return _vm.$set(_vm.item, "is_receipt", $event)
                    }
                  },
                  model: {
                    value: _vm.item.financial_posting_type_id,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "financial_posting_type_id", $$v)
                    },
                    expression: "item.financial_posting_type_id"
                  }
                }),
                _c("EmcFormDateTextField", {
                  attrs: {
                    label: _vm.item.is_receipt
                      ? "Data da entrada*"
                      : "Data da saída*",
                    md: "6",
                    outlined: "",
                    color: _vm.color,
                    error: _vm.errors.length > 0,
                    "error-messages": _vm.errors.due_date
                  },
                  model: {
                    value: _vm.item.due_date,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "due_date", $$v)
                    },
                    expression: "item.due_date"
                  }
                }),
                _c("EmcFormDecimalTextField", {
                  attrs: {
                    label: "Valor*",
                    outlined: "",
                    md: "6",
                    error: _vm.errors.length > 0,
                    "error-messages": _vm.errors.posting_value_financial_item
                  },
                  model: {
                    value: _vm.item.posting_value_financial_item,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "posting_value_financial_item", $$v)
                    },
                    expression: "item.posting_value_financial_item"
                  }
                }),
                _c("EmcFormTextArea", {
                  attrs: {
                    label: "Observação",
                    outlined: "",
                    md: "12",
                    error: _vm.errors.length > 0,
                    "error-messages": _vm.errors.description
                  },
                  model: {
                    value: _vm.item.description,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "description", $$v)
                    },
                    expression: "item.description"
                  }
                }),
                !_vm.item.id && !_vm.item.cluster_key
                  ? _c(
                      "v-col",
                      {
                        attrs: {
                          cols: "12",
                          sm: "4",
                          md: _vm.item.is_repeat ? 6 : 12
                        }
                      },
                      [
                        _c(
                          "v-sheet",
                          { staticClass: "px-2" },
                          [
                            _c("v-switch", {
                              attrs: { inset: "", label: "Repetir" },
                              model: {
                                value: _vm.item.is_repeat,
                                callback: function($$v) {
                                  _vm.$set(_vm.item, "is_repeat", $$v)
                                },
                                expression: "item.is_repeat"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.item.is_repeat
                  ? _c("EmcFormTextField", {
                      attrs: {
                        label: "Parcelas",
                        md: "6",
                        outlined: "",
                        type: "Number",
                        error: _vm.errors.length > 0,
                        "error-messages": _vm.errors.number_end
                      },
                      model: {
                        value: _vm.item.number_end,
                        callback: function($$v) {
                          _vm.$set(_vm.item, "number_end", $$v)
                        },
                        expression: "item.number_end"
                      }
                    })
                  : _vm._e(),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "12" } },
                  [
                    !_vm.item.is_repeat
                      ? _c(
                          "v-sheet",
                          { staticClass: "px-2" },
                          [
                            _c("v-switch", {
                              attrs: {
                                inset: "",
                                label: _vm.item.is_receipt
                                  ? "Recebido"
                                  : "Pago",
                                color: _vm.color
                              },
                              model: {
                                value: _vm.item.was_finished,
                                callback: function($$v) {
                                  _vm.$set(_vm.item, "was_finished", $$v)
                                },
                                expression: "item.was_finished"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm.item.was_finished && !_vm.item.is_repeat
                  ? _c("EmcFormDateTextField", {
                      attrs: {
                        label: _vm.item.is_receipt ? "Recebido em" : "Pago em",
                        md: "6",
                        outlined: "",
                        color: _vm.color,
                        error: _vm.errors.length > 0,
                        "error-messages": _vm.errors.finished_at
                      },
                      model: {
                        value: _vm.item.finished_at,
                        callback: function($$v) {
                          _vm.$set(_vm.item, "finished_at", $$v)
                        },
                        expression: "item.finished_at"
                      }
                    })
                  : _vm._e(),
                _vm.item.was_finished && !_vm.item.is_repeat
                  ? _c("EmcFormDecimalTextField", {
                      attrs: {
                        label: _vm.item.is_receipt
                          ? "Valor recebido"
                          : "Valor pago",
                        outlined: "",
                        md: "6",
                        error: _vm.errors.length > 0,
                        "error-messages":
                          _vm.errors.finalized_value_financial_posting_item
                      },
                      model: {
                        value: _vm.item.finalized_value_financial_posting_item,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.item,
                            "finalized_value_financial_posting_item",
                            $$v
                          )
                        },
                        expression:
                          "item.finalized_value_financial_posting_item"
                      }
                    })
                  : _vm._e(),
                _vm.item.cluster_key
                  ? _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "v-list",
                          [
                            _c("v-subheader", { attrs: { inset: "" } }, [
                              _vm._v("Lançamentos relacionados")
                            ]),
                            _vm._l(_vm.item.items, function(lancamento, i) {
                              return _c(
                                "v-list-item",
                                {
                                  key: i,
                                  class:
                                    lancamento.id === _vm.item.id
                                      ? "grey lighten-2"
                                      : null
                                },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    [
                                      _c("v-icon", {
                                        class: _vm.item.type.color,
                                        attrs: { dark: "" },
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.item.type.icon
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", {
                                        domProps: {
                                          textContent: _vm._s(lancamento.name)
                                        }
                                      }),
                                      _c("v-list-item-subtitle", {
                                        domProps: {
                                          textContent: _vm._s(
                                            lancamento.full_numbers
                                          )
                                        }
                                      }),
                                      lancamento.finalized_value_financial_posting_item
                                        ? _c(
                                            "v-list-item-subtitle",
                                            [
                                              _c(
                                                "v-chip",
                                                {
                                                  staticClass: "ma-2",
                                                  attrs: {
                                                    color: "success",
                                                    outlined: "",
                                                    small: ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        "x-small": "",
                                                        left: ""
                                                      }
                                                    },
                                                    [_vm._v(" mdi-cash ")]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("cash")(
                                                          lancamento.finalized_value_financial_posting_item
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-chip",
                                                {
                                                  staticClass: "ma-2",
                                                  attrs: {
                                                    outlined: "",
                                                    small: ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        "x-small": "",
                                                        left: ""
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " mdi-calendar-today "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("datetime")(
                                                          lancamento.finished_at
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-action",
                                    [
                                      _c(
                                        "v-btn",
                                        { attrs: { icon: "" } },
                                        [
                                          lancamento.value_is_late
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          var attrs = ref.attrs
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "orange"
                                                                    }
                                                                  },
                                                                  "v-icon",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "mdi-alert-circle"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Valor em atraso")
                                                  ])
                                                ]
                                              )
                                            : _vm._e(),
                                          lancamento.was_finished
                                            ? _c(
                                                "v-icon",
                                                { attrs: { color: "green" } },
                                                [_vm._v("mdi-check-circle")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c("OptionsModalUpdateItem", {
              attrs: { show: _vm.showOptions },
              on: {
                "update:show": function(value) {
                  return (_vm.showOptions = value)
                },
                onChanged: _vm.save
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: "12", md: _vm.md, sm: _vm.sm } },
    [
      _c(
        "v-sheet",
        {
          attrs: {
            outlined: "",
            color: _vm.reload ? "red lighten-4" : null,
            rounded: ""
          }
        },
        [
          _c("emc-card-stats", {
            attrs: {
              color: "red lighten-1",
              icon: "mdi-cash-register",
              "icon-large": "",
              title: _vm.title,
              value: _vm.valueFormatted,
              "sub-text": _vm.subTitle,
              elevation: "0",
              "min-height": "260"
            },
            scopedSlots: _vm._u([
              {
                key: "actions",
                fn: function() {
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", disabled: _vm.reload },
                        on: {
                          click: function($event) {
                            return _vm.$emit("onClickedExpensesMonth")
                          }
                        }
                      },
                      [
                        _c("v-icon", { attrs: { color: "green" } }, [
                          _vm._v("mdi-filter-outline")
                        ])
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
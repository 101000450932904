<template>
    <v-col cols="12">
        <apexchart height="250" type="area" :options="chartOptions" :series="series"></apexchart>
    </v-col>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    data () {
        return {
            chartOptions: {
                chart: {
                    id: 'release-chart',
                    toolbar: false
                },
                xaxis: {
                    categories: ['Jan-23', 'Fev-23', 'Mar-23', 'Abr-23', 'Mai-23', 'Jun-23', 'Jul-23', 'Ago-23', 'Set-23', 'Out-23', 'Nov-23', 'Dez-23'],
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                        }
                    },
                },
                colors:['#27AE60'],
                dataLabels: {
                    formatter: function (value) {
                        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                    }
                }
            },
            series: [
                {
                    name: 'Saldo',
                    data: []
                }
            ]
        }
    },
    props: { 
        show: { type: Boolean, default: false },
        postingStatusFinancialIds: { type: Array, required: false}
    },
    computed: {
        dialog: {
            get() {
                return this.show
            },
            set(val) {
                this.$emit('update:show', val)
            }
        },
        statusIds: {
            get() {
                return this.postingStatusFinancialIds
            }
        }
    },
    watch: {
        statusIds(val) {
            this.getData()
        }
    },
    methods: {
        ...mapActions('finance', ['ActionCashForecastIndex']),
        getData() {

            let params = {
                filterStatus: this.statusIds
            }

            this.ActionCashForecastIndex(params)
                .then((r) => {
                    this.series = [
                        { name: 'Saldo', data: r.data},
                    ]
                })
        },
        getLast12Months() {
            var months = []; // Array para armazenar os meses

            // Obtém a data atual
            var currentDate = new Date();
            var month;
            var year;

            // Loop para retroceder 12 meses
            for (var i = 0; i < 9; i++) {
                // Adiciona o mês atual ao array
                month = currentDate.toLocaleString('default', { month: 'short' }) 
                year = currentDate.getFullYear()
                months.push(month + " " + year);

                // Retrocede um mês
                currentDate.setMonth(currentDate.getMonth() + 1);
            }

            this.chartOptions.xaxis.categories = months;

            return months;
        }
    },
    created() {
        this.getData();
        this.getLast12Months();
    }
}
</script>
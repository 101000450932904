var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: _vm.cols, md: _vm.md } },
    [
      _c("v-autocomplete", {
        attrs: {
          items: _vm.items,
          disabled: _vm.loading || _vm.disabled,
          outlined: "",
          label: _vm.label,
          "item-text": "name",
          "item-value": "id",
          "hide-details": _vm.hideDetails,
          loading: _vm.loading,
          error: _vm.error,
          "error-messages": _vm.errorMessages
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function(data) {
              return [
                _c(
                  "v-list-item-icon",
                  [_c("v-icon", [_vm._v(_vm._s(data.item.icon))])],
                  1
                ),
                _c(
                  "v-list-item-content",
                  [
                    _c("v-list-item-title", {
                      domProps: { innerHTML: _vm._s(data.item.name) }
                    })
                  ],
                  1
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.model,
          callback: function($$v) {
            _vm.model = $$v
          },
          expression: "model"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <v-col :cols="cols" :md="md">
      
      <emc-form-auto-complete 
        v-model="model"
        :items="items"
        :label="label"
        :outlined="outlined"
        :disabled="disabled"
        :error-messages="errorMessages"
        :error="error"
        :loading="loading"
        :hide-no-data="hideNoData"
        @onClick="getData"
        :multiple="multiple"
        small-chips
      ></emc-form-auto-complete>
    </v-col>
  </template>
  
  <script>
  import { mapActions } from 'vuex'
  export default {
    name: 'SelectPostingStatusFinancial',
    props: {
        cols: { type: [String, Number], default: 12 },
        md: { type: [String, Number], default: 6 },
        value: { type: [String, Number, Object, Array] },
        errorMessages: { type: Array, required: false },
        error: { type: Boolean, required: false },
        disabled: { type: Boolean, required: false },
        outlined: { type: Boolean, required: false },
        item: { type: Object, required: false },
        hideNoData: { type: Boolean, required: false },
        label: { type: String, default: 'Status'},
        multiple: { type: Boolean, required: false }
    },
    data () {
      return {
        loading: false,
        collection: []
      }
    },
    computed: {
      model: {
          get() {
              return this.value
          },
          set(val) {
              this.$emit('input', val)
          }
      },
      items: {
        get() {
            if(this.collection.length > 0) {
              return this.collection
            } else {
              return [this.item]
            }
        },
        set(val) {
           this.collection = val
        }
      }
  
    },
    methods: {
      ...mapActions('finance', ['ActionPostingStatusFinancialIndex']),
      getData() {
  
        if(this.items.length > 2)
            return;
          
        this.loading = true
        this.ActionPostingStatusFinancialIndex()
                .then((r) => {
                    this.items = r.data
                })
                .finally(() => {
                    this.loading = false
                })
      }
    }
  }
  </script>
<template>
    <v-container>
        <v-card>
            <v-card-text>Adicionar filtros específicos</v-card-text>
        </v-card>
    </v-container>
    
</template>

<script>
export default {
    name: "PostingFilters"
}
</script>
<template>
    <emc-dialog-default 
            :show="dialog" 
            @update:show="(value) => dialog = value"
            title="Previsão de receita dos próximos meses"
            max-width="80%"
            icon="mdi-chart-areaspline-variant"
        >
            <template v-slot:text>
                <v-row class="mb-3 mt-2">
                    <v-spacer></v-spacer>
                    <SelectPostingStatusFinancial 
                        v-model="postingStatusFinancialIds"
                        :item="status"
                        outlined
                        multiple
                        label="Fitrar por status"
                        md="3"
                    />
                </v-row>
                <v-row class="mb-3 mt-2">
                    <ChartGraphCashForecast :postingStatusFinancialIds.sync="postingStatusFinancialIds"/>
                </v-row>
            </template>
        </emc-dialog-default>

</template>

<script>

import ChartGraphCashForecast from '@/modules/management/finance/pages/dashboard/ChartGraphCashForecast.vue';
import SelectPostingStatusFinancial from '../../finance/pages/posting/components/SelectPostingStatusFinancial.vue'

import { mapActions } from 'vuex'

export default {
    data () {
        return {
            postingStatusFinancialIds: [1],
            status: {}
        }
    },
    props: { 
        show: { type: Boolean, default: false }
    },
    computed: {
        dialog: {
            get() {
                return this.show
            },
            set(val) {
                this.$emit('update:show', val)
            }
        }
    },
    components: {
        ChartGraphCashForecast,
        SelectPostingStatusFinancial
    },
    methods: {
        ...mapActions('finance', ['ActionPostingStatusFinancialShow']),
        getStatus() {

            this.ActionPostingStatusFinancialShow({ id: 1})
                .then((r) => {
                        this.status = r.data
                    })

        }
    },
    created() {
        this.getStatus()
    }

}
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("emc-dialog-default", {
    attrs: {
      show: _vm.dialog,
      title: "Previsão de receita dos próximos meses",
      "max-width": "80%",
      icon: "mdi-chart-areaspline-variant"
    },
    on: {
      "update:show": function(value) {
        return (_vm.dialog = value)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "text",
        fn: function() {
          return [
            _c(
              "v-row",
              { staticClass: "mb-3 mt-2" },
              [
                _c("v-spacer"),
                _c("SelectPostingStatusFinancial", {
                  attrs: {
                    item: _vm.status,
                    outlined: "",
                    multiple: "",
                    label: "Fitrar por status",
                    md: "3"
                  },
                  model: {
                    value: _vm.postingStatusFinancialIds,
                    callback: function($$v) {
                      _vm.postingStatusFinancialIds = $$v
                    },
                    expression: "postingStatusFinancialIds"
                  }
                })
              ],
              1
            ),
            _c(
              "v-row",
              { staticClass: "mb-3 mt-2" },
              [
                _c("ChartGraphCashForecast", {
                  attrs: {
                    postingStatusFinancialIds: _vm.postingStatusFinancialIds
                  },
                  on: {
                    "update:postingStatusFinancialIds": function($event) {
                      _vm.postingStatusFinancialIds = $event
                    },
                    "update:posting-status-financial-ids": function($event) {
                      _vm.postingStatusFinancialIds = $event
                    }
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }